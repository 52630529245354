.kuura-chat-toggle {
  position: fixed;
  cursor: pointer;
  outline: none;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
  z-index: 7426172;
  background-color: white;
  border: 1px solid #eee;
  right: 20px;
  bottom: 20px;
  width: 70px;
  height: 70px;
  transition: box-shadow 0.2s;
  border-radius: 50%;
  padding: 0;
  color: #fff;
}

.kuura-chat-toggle:hover {
  box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.1),
    0 1px 14px 0 rgba(0, 0, 0, 0.05);
}

.feedback-toggle {
  margin: auto;
  position: relative;
  right: 3px;
  top: 2px;
  width: 50px;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
