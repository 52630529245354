@import "~antd/dist/antd.css";

.no-ie-pls {
  padding: 4rem;
  text-align: center;
}

.no-ie-pls p:first-of-type {
  margin-bottom: 0;
}
